import React, { useState, useEffect } from 'react';
import './FlipBoard.css';

const FlipBoard = ({ text }) => {
  const [currentLetters, setCurrentLetters] = useState(Array(text.length).fill('A'));

  useEffect(() => {
    const animationDuration = 20;
    const textLength = text.length;

    const updateLetters = () => {
      setCurrentLetters((prevLetters) => {
        return prevLetters.map((letter, index) => {
          if (letter !== text[index]) {
            const nextCharCode = letter.charCodeAt(0) + 1;
            const nextLetter = String.fromCharCode(nextCharCode > text[index].charCodeAt(0) ? text[index].charCodeAt(0) : nextCharCode);
            return nextLetter;
          }
          return letter;
        });
      });
    };

    const intervalId = setInterval(updateLetters, animationDuration);

    return () => clearInterval(intervalId);
  }, [text]);

  return (
    <>{currentLetters}</>
    // <div className="flipboard">
    //   {currentLetters.map((letter, index) => (
    //     <span key={index} className="letter">
    //       {letter}
    //     </span>
    //   ))}
    // </div>
  );
};

export default FlipBoard;
